@import 'src/styles/variables';

.button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.46, 0, 0.03, 0.99);
  font-weight: 600;
  cursor: pointer;

  height: 44px;
  padding: 0 16px;
  margin-top: 5px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;

  background-color: var(--color-B300);
  color: var(--color-L100);

  &:hover {
    background-color: var(--color-D100);
    color: var(--color-L100);
  }

  @media screen and (max-width: $breakpoint-mobile) {
    height: 36px;
  }
}

.badge {
  & > span {
    @media screen and (max-width: 400px) {
      font-size: 9px;
    }
  }
}
